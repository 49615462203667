@use 'bulma/bulma';

.dva-block-home-hero {
  position: relative;

  &__background {
    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__panel {
    background-color: bulma.$link;
    padding: 2rem;
  }

  &__heading {
    //
  }

  &__list {
    //
  }

  &__button {
    //
  }

  &__subheading {
    //
  }
}
