@use 'bulma/bulma';

.dva--no-scroll {
  overflow: hidden;
}

.columns.dva--sided-columns-left {
  @include bulma.mobile {
    display: flex;
    flex-direction: column-reverse;

    .column {
      flex-basis: initial;
    }
  }
}
