@use 'bulma/bulma';

body {
  margin-top: #{bulma.$navbar-height}; // + bulma.$navbar-padding-vertical
}

.dva-comp-navbar {
  flex-direction: column;
}

.dva-comp-navbar-top {
  background-color: bulma.$link;

  &__list {
    display: flex;
    justify-content: flex-end;

    li:not(:last-child) {
      border-right: 1px solid white;
    }
  }
}
